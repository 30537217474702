import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import Image from '../../../../components/Image/Image';
import Container from './Container';

const ImageGrid = styled(Container)`
${tw`grid grid-cols-3 gap-4`}
`;

const Gallery = ({ images }) => (
  <ImageGrid>
    {images.map(({ fixed, id }) => (
      <Image key={id} src={fixed.src} />
    ))}
  </ImageGrid>
);

Gallery.defaultProps = {
  images: [],
};

Gallery.propTypes = {
  images: PropTypes.arrayOf({
    id: PropTypes.string,
    fixed: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
};
export default Gallery;
