import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import EmbeddedAsset from './EmbeddedAsset';
// eslint-disable-next-line import/no-cycle
import EmbeddedEntry from './EmbeddedEntry/EmbeddedEntry';
import baseOptions from './baseOptions';

const options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.EMBEDDED_ASSET]: (node) => <EmbeddedAsset node={node} />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => <EmbeddedEntry node={node} />,
  },
};

export default options;
