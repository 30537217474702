/* eslint-disable react/prop-types */
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseImage from '../../../../components/Image/Image';
import Blocks from '../../../../components/Blocks/Blocks';
import ContentContainer from '../../../../components/Blocks/ContentContainer/ContentContainer';
import BaseContentTitle from '../../../../components/Blocks/ContentTitle/ContentTitle';
import ContentBaseline from '../../../../components/Blocks/ContentBaseline/ContentBaseline';
import List from '../../../../components/Blocks/List/List';
import BaseListItem from '../../../../components/Blocks/ListItem/ListItem';
import BaseBlock from '../../../../components/Blocks/Block/Block';
import baseOptions from '../baseOptions';

const Title = styled.h2`
  ${tw`font-montserrat font-medium text-black-100 text-4xl sm:text-5xl leading-none mb-12`}
`;

const BlocksContainer = styled(Blocks)`
  ${tw`grid md:grid-cols-12 md:pb-16`}
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
`;

const ContentTitle = styled(BaseContentTitle)`
  font-size: 2rem;
`;

const Image = styled(BaseImage)`
  ${tw`md:col-span-7`}
`;

const Block = styled(BaseBlock)`
  ${tw`md:col-span-5 px-16`}
`;

const ListItem = styled(BaseListItem)`
  ${tw`text-sm`}
`;

const options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.QUOTE]: (node, children) => <ContentBaseline>{children}</ContentBaseline>,
  },
};

const TipsAndTricks = ({
  sectionTitle,
  tipsTitle,
  image,
  content,
  backgroundColor,
}) => (
  <div className="my-20">
    <Title>{sectionTitle}</Title>
    <BlocksContainer>
      <Image src={image.fixed.src} />
      <Block bgColor={backgroundColor}>
        <ContentContainer>
          <ContentTitle>
            {tipsTitle}
          </ContentTitle>
          {renderRichText(content, options)}
        </ContentContainer>
      </Block>
    </BlocksContainer>
  </div>
);

export default TipsAndTricks;
