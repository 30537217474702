import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import Bold from './Bold';
import Paragraph from './Paragraph';
import HyperLink from './Hyperlink';
import Hr from './Hr';
import Heading2 from './Heading2';
import Heading3 from './Heading3';
import Italic from './Italic';

const baseOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => <Heading2>{children}</Heading2>,
    [BLOCKS.HEADING_3]: (_node, children) => <Heading3>{children}</Heading3>,
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
    [INLINES.HYPERLINK]: (node, children) => <HyperLink node={node}>{children}</HyperLink>,
    [BLOCKS.HR]: () => <Hr />,
  },
};

export default baseOptions;
