import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Facebook from '../../../components/Icons/Facebook';
import Instagram from '../../../components/Icons/Instagram';
import Pinterest from '../../../components/Icons/Pinterest';
import BaseImage from '../../../components/Image/Image';
import ProjectInfo from '../ProjectInfo/ProjectInfo';
import NavigationBar from '../../../widgets/NavigationBar/NavigationBar';

const HeroContainer = styled.div`
    ${tw`flex flex-col h-screen overflow-hidden`}
`;
const Container = styled.div`
    ${tw`flex flex-col text-white justify-center items-center bg-black-100 w-full order-1 p-8`}
    height: calc((100vh - 4rem)/ 2);
`;

const PreTitleContainer = styled.div`
    ${tw`flex`}
`;

const PreTitle = styled.div`
    ${tw`font-montserrat font-light uppercase font-light text-sm mx-2 mb-4 tracking-widest border-b border-solid border-white leading-9`}
`;

const TitleTag = styled.h1`
  ${tw`text-center`}
`;

const Title = styled.span`
    ${tw`font-lato uppercase font-bold text-4xl md:text-5xl tracking-wider mb-1`}
`;
const SubTitle = styled.p`
    ${tw`font-openSans font-semibold text-xs tracking-widest`}
`;

const Socials = styled.div`
    ${tw`flex mt-10`}
`;

const Social = styled.a`
    ${tw`flex items-center mx-2 md:mx-8`}
`;

const SocialText = styled.span`
    ${tw`font-openSans font-semibold text-xs uppercase tracking-widest ml-2`}
`;

const Image = styled(BaseImage)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const NavigationBarContainer = styled.div`
  ${tw`order-3 md:order-2`}
`;

const Grid = styled.div`
    ${tw`md:grid md:gap-8 lg:gap-12 md:grid-cols-2 order-2 md:order-3`}
    height: calc((100vh - 4rem)/ 2);
`;

const socials = [
  {
    type: 'facebook', component: Facebook, text: 'Like it', createShareUrl: (url) => `//www.facebook.com/sharer.php?u=${url}`,
  },
  {
    type: 'instagram', component: Instagram, text: 'Repost it', createShareUrl: () => '#',
  },
  {
    DocumentType: 'pinterest', component: Pinterest, text: 'Pint it', createShareUrl: (url) => `//pinterest.com/pin/create/button?url=${url}`,
  },
];

const Hero = ({
  canonical,
  title,
  subTitle,
  image: { url: imageUrl }, projectInfo: { description, place, surface },
}) => (
  <HeroContainer>
    <Container>
      <PreTitleContainer>
        <PreTitle>Architecture</PreTitle>
        <PreTitle>Decoration</PreTitle>
      </PreTitleContainer>
      <TitleTag>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </TitleTag>
      <Socials>
        {socials.map(({
          component, text, createShareUrl, type,
        }) => {
          const Component = component;
          return (
            <Social href={createShareUrl(canonical)} target="_blank" rel="noreferrer" aria-label={type}>
              <Component color="white" width="24px" height="24px" />
              <SocialText>{text}</SocialText>
            </Social>
          );
        })}
      </Socials>
    </Container>
    <NavigationBarContainer>
      <NavigationBar />
    </NavigationBarContainer>
    <Grid>
      <Image src={`${imageUrl}?h=800&fl=progressive&q=50`} />
      <ProjectInfo description={description} place={place} surface={surface} />
    </Grid>
  </HeroContainer>
);

Hero.propTypes = {
  canonical: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  projectInfo: PropTypes.shape({
    description: PropTypes.string,
    place: PropTypes.string,
    surface: PropTypes.string,
  }).isRequired,
};

export default Hero;
