import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import Image from '../../../components/Image/Image';
import HeartSVG from '../../../images/heart.svg';

const H2 = styled.h2`
    ${tw`flex items-baseline font-montserrat font-medium text-3xl md:text-4xl tracking-wide mt-16 mb-6`}
`;

const HeaderPicto = styled(Image)`
    ${tw`w-4 h-4 object-contain mr-4`}
`;

const Heading2 = ({ children }) => (
  <H2>
    <HeaderPicto src={HeartSVG} />
    {children}
  </H2>
);

Heading2.defaultProps = {
  children: null,
};

Heading2.propTypes = {
  children: PropTypes.node,
};

export default Heading2;
