import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';

const A = styled.a`
    ${tw`text-gold`}
`;

const HyperLink = ({ node, children }) => (
  <A href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</A>
);

HyperLink.defaultProps = {
  children: null,
  node: {
    data: {
      uri: '',
    },
  },
};

HyperLink.propTypes = {
  children: PropTypes.node,
  node: PropTypes.shape({
    data: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
};

export default HyperLink;
