/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Section from '../../components/Section/Section';
import options from './RichText/options';
import Hero from './Hero/Hero';
import More from '../../widgets/More/More';
import Page from '../../components/Page/Page';
import RealisationCard from '../../components/RealisationCard';

const Content = styled(Section)`
    ${tw`sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg mb-20 px-4 mx-auto`}
`;

const GridSection = styled(Section)`
    ${tw`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8`}
`;

const Realisation = ({
  pageContext: { content, seo, ...rest },
}) => {
  const data = useStaticQuery(graphql`
  query my3LastRealisations {
    allContentfulRealisation(sort: {order: DESC, fields: projectDate}, limit:3) {
      edges {
        node {
          id
          title
          subTitle
          slug
          thumbnail {
            fixed(height: 800, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
    }
  }
  
  `);
  return (
    <Page
      seo={seo}
      header={() => (
        <Hero {...rest} canonical={seo.canonical} />
      )}
      main={() => (
        <>
          {content && <Content>{renderRichText(content, options)}</Content>}
          <More
            title="vous avez un projet à me confier ?"
            buttonLabel="ME CONTACTER"
            buttonSrc="/contact"
          />
          <Content className="my-20">
            <GridSection>
              {data.allContentfulRealisation.edges.map(
                ({ node }) => (
                  <RealisationCard realisation={{
                    slug: node.slug,
                    id: node.id,
                    image: node.thumbnail,
                    title: node.title,
                    subTitle: node.subTitle,
                  }}
                  />
                ),
              )}
            </GridSection>
          </Content>
        </>
      )}
    />
  );
};

Realisation.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default Realisation;
