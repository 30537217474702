/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Caroussel from './Caroussel';
import Gallery from './Gallery';
// eslint-disable-next-line import/no-cycle
import Layout2Columns from './Layout2Columns';
import TipsAndTricks from './TipsAndTricks';

export default {
  ContentfulGallery: (props) => <Gallery {...props} />,
  ContentfulLayout2Colonnes: (props) => <Layout2Columns {...props} />,
  ContentfulTrucEtAstuces: (props) => <TipsAndTricks {...props} />,
  ContentfulCaroussel: (props) => <Caroussel {...props} />,
};
