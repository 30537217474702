/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
// eslint-disable-next-line import/no-cycle
import { BLOCKS } from '@contentful/rich-text-types';
import Image from '../../../../components/Image/Image';
import baseOptions from '../baseOptions';
import Container from './Container';
import Caroussel from './Caroussel';

const Grid = styled(Container)`
    ${tw`grid grid-cols-1 gap-4 md:grid-cols-12 md:gap-8 items-center my-4`}
`;

const GridContent = styled.div`
    ${tw`col-span-6 lg:col-span-5 xl:col-span-4 `}
`;

const GridImage = styled(Image)`
    ${tw`col-span-6 lg:col-span-7 xl:col-span-8`}
`;

const BasleLine = styled.div`
  & > * {
    ${tw`font-lato text-base`}
  }
`;

const options = {
  ...baseOptions,
  renderNode: {
    ...baseOptions.renderNode,
    [BLOCKS.QUOTE]: (node, children) => <BasleLine>{children}</BasleLine>,
  },
};

const buildGridImages = (images, position) => {
  const classes = position === 'left' ? 'order-2 md:order-none' : '';
  if (images.length > 1) {
    return (
      <GridImage as="div" className={classes}>
        <Caroussel images={images} />
      </GridImage>
    );
  }
  return <GridImage src={images[0].fixed.src} className={classes} />;
};

const Layout2Columns = ({ imagePositionAtRight, images, content }) => (
  <Grid>
    {!imagePositionAtRight && buildGridImages(images, 'left')}
    <GridContent>{renderRichText(content, options)}</GridContent>
    {imagePositionAtRight && buildGridImages(images, 'right')}
  </Grid>
);

Layout2Columns.defaultProps = {
  imagePositionAtRight: false,
};

Layout2Columns.propTypes = {
  content: PropTypes.object.isRequired,
  imagePositionAtRight: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  images: PropTypes.array,
};
export default Layout2Columns;
