/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import Image from '../../../components/Image/Image';

const Asset = styled(Image)`
    ${tw`w-full my-4`}
`;

const EmbeddedAsset = ({ node: { data: { target } } }) => (
  <>
    {target.fixed && <Asset src={target.fixed.src} />}
  </>
);

EmbeddedAsset.defaultProps = {
  node: {
    data: {
      target: {
        fixed: {
          src: '',
        },
      },
    },
  },
};

EmbeddedAsset.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        fixed: PropTypes.shape({
          src: '',
        }),
      }),
    }),
  }),
};

export default EmbeddedAsset;
