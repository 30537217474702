/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import embeddedEntries from './embeddedEntries';

const EmbeddedEntry = ({ node }) => {
  const { data: { target } = { __typename: '' } } = node;
  const Component = embeddedEntries[target.__typename] || null;
  return Component && <Component {...target} />;
};

EmbeddedEntry.defaultProps = {
  node: {
    data: {
      target: {
        __typename: '',
      },
    },
  },
};

EmbeddedEntry.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        __typename: PropTypes.string,
      }),
    }),
  }),
};

export default EmbeddedEntry;
