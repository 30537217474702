import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import Arrow from '../../../images/arrow.svg';
import Heart from '../../../images/heart.svg';
import Star from '../../../images/star.svg';
import Image from '../../../components/Image/Image';

const StyledImage = styled(Image)`
  ${tw`w-4 mr-4`}
`;

const Infos = styled.div`
  ${tw`flex-col justify-center bg-gray-100 px-20 py-10 lg:w-10/12 hidden md:flex`}
`;

const Info = styled.div`
  ${tw`flex items-baseline mb-4 last:mb-0`}
`;

const Project = styled.div`
  ${tw``}
`;

const Title = styled.div`
  ${tw`font-lato font-bold uppercase text-black-100 tracking-wider`}
`;

const Description = styled.div`
  ${tw`font-lato text-sm text-black-100 tracking-wide`}
`;

const ProjectInfo = ({ place, surface, description }) => {
  const infos = useMemo(() => [
    { picto: Arrow, title: 'Lieu', text: place },
    { picto: Star, title: 'Surface', text: surface },
    { picto: Heart, title: 'Projet', text: description },
  ], [place, surface, description]);
  return (
    <Infos>
      {infos.map(({ picto, title, text }) => (
        <Info key={title}>
          <StyledImage src={picto} alt="" />
          <Project>
            <Title>{title}</Title>
            <Description>{text}</Description>
          </Project>
        </Info>
      ))}
    </Infos>
  );
};

ProjectInfo.propTypes = {
  description: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  surface: PropTypes.string.isRequired,
};
export default ProjectInfo;
