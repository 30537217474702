import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CarousselComponent from '../../../../components/Caroussel/Caroussel';

const Caroussel = ({ images, className }) => (
  <div className={classNames('my-4', className)}>
    <CarousselComponent images={images.map(({ fixed }) => fixed.src)} className="my-4" />
  </div>
);

Caroussel.defaultProps = {
  className: '',
  images: [],
};

Caroussel.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf({
    id: PropTypes.string,
    fixed: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
};
export default Caroussel;
